<template>
  <b-card bg-variant="light"
          header="Experiment reports"
          header-bg-variant="dark"
          header-text-variant="white">
    <experiment_list :elevated="false"
                     :load_data_url="url"
                     :prop_fields="fields"></experiment_list>
  </b-card>
</template>

<script>
  import experiment_list from '@/components/proposal/experiment_report/experiment_list';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'experiment_dashboard',
    components: { experiment_list },
    data() {
      return {
        url: '/documents/experiment_reports/me',
        fields: [
          ordinalNumber,
          {
            key: 'document_specification.document_id',
            label: 'ID',
          },
          {
            key: 'title',
            label: 'Title',
          },
          {
            key: 'document_specification.type',
            label: 'Infrastructure',
          },
          {
            key: 'created_at',
            label: 'Proposal creation date',
          },
          {
            key: 'experiment_report.states.name',
            label: 'Report status',
          },
          {
            key: 'actions',
            label: 'Actions',
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .card {
    margin: 2rem;
  }
</style>
